
function AboutUsPage() {
    return<>
        <div class="single-blog-wrapper">


<div class="single-blog-post-thumb">
    <img src={require("../img/bathroom4.jpg")} alt=""/>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-md-8">
            <div class="regular-page-content-wrapper section-padding-80">
                <div class="regular-page-text">
                    <h2>About Darak Outlet</h2>
<p>Welcome to Darak Outlet, your new destination for exceptional furniture shopping with a touch of
innovation, located in the heart of Cairo, Egypt. Darak Outlet is an exciting new e-commerce
platform that's set to launch on Jnuary 1st, 2024. We aim to redefine the way you shop for
furniture, offering exquisite designs, quality craftsmanship, and a secret innovation poised to
revolutionize your shopping experience</p>
<h2>Our Story
</h2>
<p>At Darak Outlet, our story is born out of a love for furniture and a vision for the future of online
shopping in the MENA, GCC, and the South West and Central Asia Markets. We understand that
furniture isn't merely about filling a space; it's about creating an atmosphere that reflects your
style and personality. Our journey began with a simple idea: to make the process of finding the
perfect furniture as enjoyable as owning it.
</p>
                    <blockquote>
                        <h6><i class="ti-quote-left" aria-hidden="true"></i> Unveiling the Future:</h6>
                        <span> 3D Visualization</span>
                    </blockquote>

                    <p>While we're proud of the exceptional range of furniture products we will soon offer, we are even
more excited about the future. Darak Outlet is on the verge of introducing a groundbreaking
feature that will change the way you shop for furniture forever – our cutting-edge 3D visualization
model.
Imagine being able to virtually place that stylish sofa in your living room or that elegant dining set
in your kitchen. With our upcoming 3D visualization tool, you can do just that. This feature will
allow you to see how our furniture pieces will fit seamlessly into your space, ensuring that you
make the right choice every time.</p>
<blockquote>
<h6><i class="ti-quote-left" aria-hidden="true"></i> A New Beginning:</h6>

                        <span> Bathroom Solutions</span>
                    </blockquote>

                    <p>In our initial phase, Darak Outlet will exclusively focus on bathroom solutions. Our curated
collection of bathroom furniture and accessories is carefully selected for quality, design, and
functionality. While installation options won't be available in this phase, we're committed to
providing you with the finest bathroom solutions to transform your space.</p>
<blockquote>
                        <span> What Sets Us Apart</span>
                    </blockquote>

                    <p>Global Selection: Darak Outlet collaborates with vendors from the GCC and Europe, offering a
diverse range of bathroom products for the MENA, GCC, and the South West and Central Asia
Markets.
Quality Craftsmanship: We prioritize craftsmanship, ensuring that every piece we offer is built to
last. You can trust our bathroom solutions to stand the test of time.
Innovative Shopping Experience: With the upcoming 3D visualization model, we're taking
bathroom shopping to a new level. You'll be able to see exactly how each piece will fit into your
bathroom, eliminating the guesswork.
Customer-Centric Approach: We believe in putting our customers first. Our friendly and
knowledgeable team is always ready to assist you in finding the perfect bathroom solutions for
your space.</p>
<blockquote>
                        <span>Our Commitment</span>
                    </blockquote>

                    <p>At Darak Outlet, we're committed to transforming the way you experience bathroom shopping,
providing the MENA, GCC, and the South West and Central Asia Markets with high-quality
bathroom solutions and an interactive, immersive, and convenient shopping experience. As a
brand-new startup launching on January 1st, 2024, we invite you to explore our initial phase of
bathroom solutions. Stay tuned for our upcoming 3D visualization model – the future of bathroom
shopping is right around the corner.
Join us on this exciting journey of innovation and style. Darak Outlet is more than just bathroom
solutions; it's about making your bathroom uniquely yours. Shop with us today and look forward
to the future of bathroom shopping tomorrow.</p>
<blockquote>
                        <span>A Sneak Peek into Our Design Philosophy</span>
                    </blockquote>

                    <p>At Darak Outlet, we're not just about offering furniture; we're about curating pieces that redefine
your living space. Our design preference is eclectic, drawing inspiration from contemporary art
deco, a style that effortlessly combines the old and the new. It's a teaser for an exciting
announcement we have in store.
Our product selection spans various design schools, including tribal, Bauhaus, Baroque, biophilic,
and much more. Get ready to embark on a journey through an eclectic world of design, where
every piece tells a unique story, and every room becomes a canvas for your personal style.
Stay tuned for our official announcement and explore a world of design that goes beyond the
ordinary. Darak Outlet is about to redefine your space in ways you've never imagined.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="blog-wrapper section-padding-80">
        <div class="container">
            <div class="row">
               
               
                <div class="col-12 col-lg-6">
                    <div class="single-blog-area mb-50">
                        <img src={require("../img/product-img/8031R-60_2.jpg")} alt=""/>
                        <div class="post-title">
                            <a href="#">Darak Outlet's vision is to revolutionize interior design globally with a diverse
'Outlet' concept, innovative features like our 3D visualization tool, and a commitment to creating
unique homes</a>
                        </div>
                        <div class="hover-content">
                            <div class="hover-post-title">
                            <a href="#">Darak Outlet's vision is to revolutionize interior design globally with a diverse
'Outlet' concept, innovative features like our 3D visualization tool, and a commitment to creating
unique homes</a>
                            </div>
                            <p>.</p>
                            <a href="#">Continue reading <i class="ti-angle-right"></i></a>
                        </div>
                    </div>
                </div>
               
                <div class="col-12 col-lg-6">
                    <div class="single-blog-area mb-50">
                        <img src={require("../img/product-img/ANS31-LarchCanapa.jpg")} alt=""/>
                        <div class="post-title">
                            <a href="#">At Darak Outlet, we're revolutionizing interior design with a global 'Outlet'
concept and innovative platform, connecting suppliers and clientele. Stay tuned for exciting
launch features</a>
                        </div>
                        <div class="hover-content">
                            <div class="hover-post-title">
                            <a href="#">At Darak Outlet, we're revolutionizing interior design with a global 'Outlet'
concept and innovative platform, connecting suppliers and clientele. Stay tuned for exciting
launch features</a>
                            </div>
                            <p>.</p>
                            <a href="#">Continue reading <i class="ti-angle-right"></i></a>
                        </div>
                    </div>
                </div>
               
               
               
               
            </div>
        </div>
    </div>
    </>
}
export default AboutUsPage;