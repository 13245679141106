
import axios from "axios";
export default async function GetVanitis() {
    try {
      const response = await axios.get('https://darakapi.darakoutlet.com/api/Vanities/');
      return response;
    } catch (error) {
      console.error(error);
    }
  }
