/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 model.gltf --transform 
Files: model.gltf [134.9MB] > model-transformed.glb [7.11MB] (95%)
Author: OzerGokmen (https://sketchfab.com/OzerGokmen)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/dark-theme-bathroom-7ea8917e4cfe49d4a170da695738bf74
Title: Dark Theme Bathroom
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/model-transformed.glb')
  return (
    <group {...props} dispose={null} rotation={[0.5,0.5,-0.29]}>
      <mesh geometry={nodes.defaultMaterial.geometry} material={materials.dusbasl_g_1} position={[-71.933, 57.237, -73.764]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_1.geometry} material={materials.sabunluk} position={[-102.086, 79.195, 32.996]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_2.geometry} material={materials.dusbasl_g_2} position={[-86.896, 65.952, -58.151]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_3.geometry} material={materials.duso1} position={[-85.838, 61.272, -58.846]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_4.geometry} material={materials.material_30} position={[-83.82, 88.094, -126.431]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_5.geometry} material={materials.material_29} position={[-74, 88.651, -126.423]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_6.geometry} material={materials.material_28} position={[-63.136, 88.651, -126.423]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_7.geometry} material={materials.material_27} position={[-53.945, 83.517, -126.828]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_8.geometry} material={materials.ayna} position={[-106.377, 102.311, 59.983]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_9.geometry} material={materials.material_25} position={[77.404, 83.663, -126.828]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_10.geometry} material={materials.material_24} position={[44.995, 88.094, -126.431]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_11.geometry} material={materials.material_23} position={[87.484, 86.692, -126.908]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_12.geometry} material={materials.material_22} position={[-43.685, 88.651, -123.457]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_13.geometry} material={materials.material_21} position={[53.073, 88.651, -126.423]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_14.geometry} material={materials.cam3} position={[65.424, 86.338, -126.311]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_15.geometry} material={materials.havluu} position={[15.558, 98.196, -111.805]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_16.geometry} material={materials.cam2} position={[55.348, 73.667, -53.349]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_17.geometry} material={materials.cam1} position={[-63.668, 73.667, -71.94]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_18.geometry} material={materials.klozet} position={[85.824, 22.352, 64.989]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_19.geometry} material={materials.surahi} position={[87.12, 17.207, -5.738]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_20.geometry} material={materials.kova1} position={[66.548, 17.207, -7.138]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_21.geometry} material={materials.demir2} position={[103.104, 79.927, -7.468]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_22.geometry} material={materials.demir1} position={[14.053, 98.159, -115.029]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_23.geometry} material={materials.tahta1} position={[-56.204, 1.006, 59.92]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_24.geometry} material={materials.cita} position={[-63.668, 73.667, -73.196]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_25.geometry} material={materials.kare} position={[105.515, 58.178, -57.045]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_26.geometry} material={materials.musluk2} position={[102.251, 50.074, -66.426]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_27.geometry} material={materials.petek} position={[103.193, 29.286, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_28.geometry} material={materials.kuvet} position={[70.34, 34.166, -61.794]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_29.geometry} material={materials.tahta2} position={[0, 6.032, -66.637]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_30.geometry} material={materials.musluk} position={[-102.233, 56.788, 60.706]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_31.geometry} material={materials.lavabo} position={[-84.394, 52.539, 60.657]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_32.geometry} material={materials.wall} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
      <mesh geometry={nodes.defaultMaterial_33.geometry} material={materials.dolap} position={[-86.454, 41.408, 59.309]} rotation={[-Math.PI / 2, 0, 0]} scale={100} />
    </group>
  )
}

useGLTF.preload('/model-transformed.glb')
